import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { components, OptionProps } from 'react-select';
import { useLocations } from '@web/shop-logic';
import SelectDropdown from '@/components/atoms/SelectDropdown';
import { CitiesOptionData } from '@/components/organisms/CitiesSelectComponents';
import { LocationOn, DestinationFrom } from '@/icons';
import { CitiesOptionValues } from '@/utils/citiesSelectUtils';

const Option = ({ data, children, ...props }: OptionProps<CitiesOptionData, false>) => (
  <components.Option {...props} data={data}>
    <div className="flex menu-row">
      <LocationOn className="w-2.5 h-2.5 mr-1" />
      {data.label}
    </div>
  </components.Option>
);

type HandleCityFnc = (value: CitiesOptionValues | null) => void;

interface VikendoCitySelectProps {
  onFromChange: HandleCityFnc;
  onToChange: HandleCityFnc;
}

const VikendoCitySelect: React.FC<VikendoCitySelectProps> = ({ onFromChange, onToChange }) => {
  const { t } = useTranslation();
  const { data = [] } = useLocations();

  const stations = useMemo(
    () =>
      data.flatMap((location) =>
        location.name.includes('Česko')
          ? [{ label: location.name, value: location.id.toString() }]
          : [],
      ),
    [data],
  );

  return (
    <>
      <SelectDropdown
        Icon={DestinationFrom}
        data-id="departure-station"
        className="rounded-sm w-1/3 sm:w-full lg:mx-0.5 vikendo"
        loadingMessage={() => t('accessibility.loader')}
        placeholder={t('arrivalsDepartures.firstStation')}
        options={stations}
        components={{ Option }}
        onChange={onFromChange}
      />
      <SelectDropdown
        Icon={LocationOn}
        data-id="arrival-station"
        className="rounded-sm w-1/3 sm:w-full lg:mx-0.5 vikendo"
        loadingMessage={() => t('accessibility.loader')}
        placeholder={t('arrivalsDepartures.lastStation')}
        options={stations}
        components={{ Option }}
        onChange={onToChange}
      />
    </>
  );
};

export default VikendoCitySelect;
