import { Trans, useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { stringify } from 'qs';
import { ButtonLink } from '@/components/atoms/Button';
import SelectDropdown from '@/components/atoms/SelectDropdown';
import VikendoCitySelect from '@/components/content/vikendo/VikendoCitySelect';
import { DestinationFrom } from '@/icons';
import { CitiesOptionValues } from '@/utils/citiesSelectUtils';

const VikendoWrapper: React.FC = () => {
  const { t } = useTranslation();

  const tariffOptions = [
    { value: 'cokoliv', label: t('vikendo.label.1') },
    { value: '1', label: t('vikendo.label.2') },
    { value: '2', label: t('vikendo.label.3') },
    { value: '3', label: t('vikendo.label.4') },
    { value: '4', label: t('vikendo.label.5') },
  ];

  const [adventureType, setAdventureType] = useState<CitiesOptionValues | null>(tariffOptions[0]);
  const [fromLocationId, setFromLocationId] = useState<string | undefined>('kdekoliv');
  const [toLocationId, setToLocationId] = useState<string | undefined>('kdekoliv');

  const linkParams = stringify(
    {
      kategorie: adventureType.value,
      kde: toLocationId,
      odkud: fromLocationId,
      variant: 'RegioJet',
    },
    { addQueryPrefix: true },
  );

  const onChange = (option: CitiesOptionValues | null) => option && setAdventureType(option);

  return (
    <div className="bg-primary-yellow rounded-sm p-1.5 lg:px-2 lg:pt-3 lg:pb-4">
      <h2 className="h2 text-center mb-3">
        <Trans i18nKey="vikendo.header" />
      </h2>
      <div className="flex sm:block">
        <SelectDropdown
          className="w-1/3 sm:w-full rounded-sm lg:mx-0.5 vikendo"
          Icon={DestinationFrom}
          options={tariffOptions}
          placeholder={t('vikendo.type.placeholder')}
          value={adventureType}
          onChange={onChange}
        />
        <VikendoCitySelect
          onFromChange={(value) => setFromLocationId(value.value)}
          onToChange={(value) => setToLocationId(value.value)}
        />
        <ButtonLink
          size="big"
          className="sm:w-full lg:mx-0.5"
          href={`${t('vikendo.url')}/${linkParams}`}
          target="_blank"
        >
          <Trans i18nKey="vikendo.button" />
        </ButtonLink>
      </div>
    </div>
  );
};
export default VikendoWrapper;
