import { buttonClasses } from '../strapi/ImageRow';
import StrapiImage from './StrapiImage';
import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Minimize } from '@/icons';
import { UploadFile } from '@/models/types';

interface LightBoxImageProps {
  image: UploadFile;
  toggle: () => void;
}

const LightBoxImage: FC<LightBoxImageProps> = ({ image, toggle }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (image) buttonRef.current.focus();
  }, [image]);

  if (!image) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-80 z-40 grid place-content-center sm:hidden "
      onClick={toggle}
    >
      <div className="relative page-layout h-auto rounded-sm overflow-hidden">
        <StrapiImage image={image} className="w-full h-full rounded-sm" />
        <button ref={buttonRef} onClick={toggle} className={classNames(buttonClasses, 'right-4')}>
          <Minimize className="stroke-primary-blue w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default LightBoxImage;
