import React, { Fragment } from 'react';
import classNames from 'classnames';
import LinkableHeader from '@/components/atoms/LinkableHeader';
import PageButton from '@/components/strapi/PageButton';
import RichParagraph from '@/components/strapi/RichParagraph';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ComponentPageTextVerticalAlignment } from '@/models/types';

interface TextContainerProps {
  className?: string;
  textContainer: ComponentPageTextVerticalAlignment;
}

const TextContainer: React.FC<TextContainerProps> = ({
  className,
  textContainer: { header, subheader, button, content, textVerticalAlignment },
}) => {
  const { isMobile } = useMobileWidth();
  const Component = isMobile ? Fragment : 'div';

  return (
    <Component
      className={
        isMobile
          ? undefined
          : classNames(className, textVerticalAlignment === 'center' ? 'self-center' : 'self-top')
      }
    >
      {subheader && <p className="text-neutral-gray2">{subheader}</p>}
      {header && (
        <LinkableHeader headlineLevel={2} className="h2 mb-2 sm:mt-2 sm:order-1">
          {header}
        </LinkableHeader>
      )}
      {content && (
        <div className="mb-3 sm:order-3">
          <RichParagraph text={content} />
        </div>
      )}
      {button && <PageButton className="sm:order-4" component={button} isFullWidth={false} />}
    </Component>
  );
};

export default TextContainer;
