import LightBoxImage from '../content/LightBox';
import React, { useState } from 'react';
import classNames from 'classnames';
import LinkableHeader from '@/components/atoms/LinkableHeader';
import StrapiImage from '@/components/content/StrapiImage';
import RichParagraph from '@/components/strapi/RichParagraph';
import env from '@/constants/env';
import useMobileWidth from '@/hooks/useMobileWidth';
import { Maximize } from '@/icons';
import { ComponentPageImageRow, UploadFile } from '@/models/types';

interface ImageRowProps {
  component: ComponentPageImageRow;
  removeLayoutClasses?: boolean;
}

export const buttonClasses =
  'bg-white border border-primary-blue w-5 h-5 rounded-sm absolute z-50 bottom-2 grid place-content-center right-2 focus:shadow-border';

const ImageRow: React.FC<ImageRowProps> = ({ component, removeLayoutClasses }) => {
  const [highlightImage, setHighlightImage] = useState<UploadFile>(null);
  const { isMobile } = useMobileWidth();

  const showImageModal = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    image: UploadFile,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (isMobile) {
      window.open(`${env.STRAPI_URL}${image.url}`);
      return;
    }

    setHighlightImage(image);
  };

  if (!component || !component?.pageImage?.length) {
    return null;
  }

  const { header, paragraph, pageImage } = component;

  return (
    <>
      <div className={classNames('mb-5 lg:mb-10', removeLayoutClasses ? '' : 'page-layout')}>
        <Maximize className="text-black w-5 h-5" />
        {header && (
          <LinkableHeader headlineLevel={2} className="h1 mb-2">
            {header}
          </LinkableHeader>
        )}
        {paragraph && (
          <div className="mb-2 wysiwyg wysiwyg-spacing">
            <RichParagraph text={paragraph} />
          </div>
        )}
        <div className="grid grid-flow-col gap-3 items-start auto-cols-fr sm:grid-flow-row sm:grid-cols-1">
          {pageImage.map(({ id, image, header: imageHeader, subheader, url, allowFullscreen }) => {
            const pointerClass = allowFullscreen ? '' : 'pointer-events-none';
            return (
              <a
                key={id}
                href={url ? url : undefined}
                onClick={(e) => (!url && allowFullscreen ? showImageModal(e, image) : undefined)}
                aria-label={imageHeader}
                className={classNames(
                  'z-10',
                  url
                    ? 'group hover:text-primary-blue transition-color duration-300'
                    : pointerClass,
                )}
              >
                <div className="relative overflow-hidden h-auto aspect-video mb-1 rounded-sm bg-primary-bluelight2 group-hover:shadow-modal transition-shadow duration-300">
                  <StrapiImage
                    alt={imageHeader}
                    image={image}
                    className="group-hover:transform group-hover:scale-110 transition-transform duration-200 ease-in aspect-square"
                  />
                  {allowFullscreen && (
                    <button onClick={(e) => showImageModal(e, image)} className={buttonClasses}>
                      <Maximize className="stroke-primary-blue w-3 h-3" />
                    </button>
                  )}
                </div>

                {imageHeader && (
                  <h3 className={classNames(url ? 'h3' : 'text-16 font-normal')}>{imageHeader}</h3>
                )}
                {subheader && <p>{subheader}</p>}
              </a>
            );
          })}
        </div>
      </div>
      <LightBoxImage image={highlightImage} toggle={() => setHighlightImage(null)} />
    </>
  );
};

export default ImageRow;
