import React from 'react';
import classNames from 'classnames';
import StrapiImage from '@/components/content/StrapiImage';
import TextContainer from '@/components/strapi/TextContainer';
import { ComponentPageParagraphImageRow } from '@/models/types';
import { mapBackgroundToComponent } from '@/utils/strapiUtils';

interface ParagraphImageRowProps {
  component: ComponentPageParagraphImageRow;
}

const ParagraphImageRow: React.FC<ParagraphImageRowProps> = ({ component }) => {
  if (!component) return null;
  const {
    TextContainer: textContainer,
    ImageContainer,
    background: backgroundColor,
    fullWidthBackground,
  } = component;

  const background = mapBackgroundToComponent[backgroundColor];

  const textAlign = textContainer?.textVerticalAlignment;
  const imagePosition = ImageContainer?.position;
  const trueImageSize = ImageContainer?.trueSize;

  return (
    <div className={classNames(fullWidthBackground && background)}>
      <div
        className={classNames(
          'page-layout grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-3 lg:gap-y-5',
          !fullWidthBackground && background,
          backgroundColor !== 'white' && 'rounded-sm sm:mx-2',
          textAlign && `items-${textAlign}`,
        )}
      >
        {textContainer && (
          <TextContainer className={classNames('lg:w-3/4 mx-4')} textContainer={textContainer} />
        )}
        {ImageContainer && (
          <div
            className={classNames(
              'order-last sm:mt-1',
              imagePosition === 'right' ? 'lg:order-last' : 'lg:order-first',
              backgroundColor !== 'white' && imagePosition === 'left' && '-ml-2 sm:-mr-2',
              backgroundColor !== 'white' && imagePosition === 'right' && '-mr-2 sm:-ml-2',
            )}
          >
            <StrapiImage
              alt={textContainer?.header}
              image={ImageContainer.image}
              className={classNames(
                'rounded-sm',
                trueImageSize ? 'w-auto mx-auto' : 'lg:h-96',
                ImageContainer.shadow !== 'none' && 'shadow-image',
                backgroundColor !== 'white' &&
                  imagePosition === 'left' &&
                  'lg:rounded-r-none lg:rounded-l-sm sm:rounded-t-none sm:max-h-64',
                backgroundColor !== 'white' &&
                  imagePosition === 'right' &&
                  'lg:rounded-l-none lg:rounded-r-sm sm:rounded-t-none sm:max-h-64',
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ParagraphImageRow;
