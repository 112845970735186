import React from 'react';
import classNames from 'classnames';
import { ButtonLink, ButtonSizes, ButtonVariants } from '@/components/atoms/Button';
import { ComponentPageButton } from '@/models/types';

interface PageButtonProps extends React.HTMLProps<HTMLDivElement> {
  component: ComponentPageButton;
  isFullWidth?: boolean;
  buttonSize?: ButtonSizes;
  buttonVariant?: ButtonVariants;
}

const PageButton: React.FC<PageButtonProps> = ({
  component,
  isFullWidth = true,
  className,
  buttonSize = ButtonSizes.BIG,
  buttonVariant = ButtonVariants.PRIMARY,
}) => {
  if (!component) return null;

  const { url, text } = component;

  return (
    <div className={classNames(isFullWidth && 'page-layout text-center', className)}>
      <ButtonLink href={url} size={buttonSize} className="sm:w-full" variant={buttonVariant}>
        {text}
      </ButtonLink>
    </div>
  );
};

export default PageButton;
