import React from 'react';
import { SwitchProps, styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const disabledGray = '#92A4AD';
const primaryBlue = '#00519E';
const white = '#FFF';

const StyledSwitch = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(
  ({ theme }) => ({
    width: 52,
    height: 32,
    padding: 0,
    marginRight: -16,
    '& .MuiSwitch-switchBase': {
      margin: 2,
      padding: 6,
      transform: 'translateX(1px)',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        // State when checked
        color: white,
        padding: 2,
        transform: 'translateX(20px)',
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 24,
          height: 24,
          backgroundColor: 'white',
        },
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
            primaryBlue,
          )}" d="M9.00014 16.17L5.53014 12.7C5.14014 12.31 4.51014 12.31 4.12014 12.7C3.73014 13.09 3.73014 13.72 4.12014 14.11L8.30014 18.29C8.69014 18.68 9.32014 18.68 9.71014 18.29L20.2901 7.71001C20.6801 7.32001 20.6801 6.69001 20.2901 6.30001C19.9001 5.91001 19.2701 5.91001 18.8801 6.30001L9.00014 16.17Z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          backgroundColor: primaryBlue,
          opacity: 1,
          border: '2px solid #00519E',
        },
        // Disabled state when checked
        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: disabledGray,
            borderColor: disabledGray,
          },
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
              disabledGray,
            )}" d="M9.00014 16.17L5.53014 12.7C5.14014 12.31 4.51014 12.31 4.12014 12.7C3.73014 13.09 3.73014 13.72 4.12014 14.11L8.30014 18.29C8.69014 18.68 9.32014 18.68 9.71014 18.29L20.2901 7.71001C20.6801 7.32001 20.6801 6.69001 20.2901 6.30001C19.9001 5.91001 19.2701 5.91001 18.8801 6.30001L9.00014 16.17Z"/></svg>')`,
          },
        },
      },
      // Disabled state when unchecked
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: disabledGray,
          borderColor: disabledGray,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: white,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: primaryBlue,
      boxShadow: 'none',
      width: 16,
      height: 16,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: 'none',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: white,
      borderRadius: '100px',
      border: '2px solid',
      borderColor: primaryBlue,
      transition: theme.transitions.create(['background-color'], {
        duration: 300,
      }),
    },
  }),
);

interface FormSwitchProps extends SwitchProps {
  name?: string;
}

const FormSwitch: React.FC<FormSwitchProps> = ({ name, ...props }) => (
  <FormControlLabel
    control={<StyledSwitch name={name} {...props} />}
    label={<span className="ml-1">{name}</span> || ''}
  />
);

export default FormSwitch;
